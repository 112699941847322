import SoundTestWithAudio from "../../types/SoundTestWithAudio";
import { selectorFamily } from "recoil";
import getUrlWithApiPrefix from "../utils/getUrlWithApiPrefix";

const getApiUrl = (hash: string) => getUrlWithApiPrefix(`/sound-test/${hash}`);

const soundTestAtom = selectorFamily<
  SoundTestWithAudio,
  SoundTestWithAudio["hash"]
>({
  key: "soundTestAtom",
  get: (hash) => async () => {
    const response = await fetch(getApiUrl(hash));
    const soundTest: SoundTestWithAudio = await response.json();

    return soundTest;
  },
});

export default soundTestAtom;
