import { selector, DefaultValue } from "recoil";

const authPromise = (() => {
  let outerResolve = (isLoggedIn: boolean) => {};

  const promise: Promise<boolean> = new Promise((resolve) => {
    outerResolve = resolve;
  });

  return {
    resolve: outerResolve,
    promise,
  };
})();

const isLoggedInAtom = selector({
  key: "kbcompare/auth",
  get: () => authPromise.promise,
  set: (_, val) => {
    if (!(val instanceof DefaultValue)) {
      authPromise.resolve(val);
    }
  },
});

export { isLoggedInAtom };
