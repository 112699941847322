import { useRecoilValue, RecoilValue } from "recoil";
import React, { Suspense } from "react";
import { LoadingOutlined } from "@ant-design/icons";

type LoadProps<T> = {
  recoilState: RecoilValue<T>;
  children: (val: T) => React.ReactElement;
};

const LoadInner = <T extends any>({ recoilState, children }: LoadProps<T>) =>
  children(useRecoilValue(recoilState));

const Load = <T extends any>({ recoilState, children }: LoadProps<T>) => (
  <Suspense fallback={<LoadingOutlined spin />}>
    <LoadInner recoilState={recoilState} children={children} />
  </Suspense>
);

export default Load;
