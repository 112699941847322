import React, { useState, useEffect } from "react";
import Page from "./Page";
import { useParams } from "react-router-dom";
import soundTestAtom from "../state/soundTest";
import Load from "./Load";
import AudioList from "./AudioList";
import { Typography, Space, Button } from "antd";
import { LikeOutlined, FlagOutlined } from "@ant-design/icons";
import { isLoggedInAtom } from "../state/auth";
import getUrlWithApiPrefix from "../utils/getUrlWithApiPrefix";
import firebase from "firebase/app";
import myLikedTestsAtom from "../state/myLikedTests";

const { Text, Title } = Typography;

const ViewTest = () => {
  const { testId }: { testId: string } = useParams();

  return (
    <Page title="">
      <Load recoilState={soundTestAtom(testId)}>
        {(soundTest) => (
          <div>
            <Title level={2}>
              {soundTest.name} <InteractionButtons />
            </Title>
            <Space direction="vertical">
              {soundTest.description && (
                <Text className="wrap-text-new-line">
                  {soundTest.description}
                </Text>
              )}
              {soundTest.recording_setup && (
                <Text>
                  <Text strong>Recording Setup: </Text>{" "}
                  {soundTest.recording_setup}
                </Text>
              )}
              {soundTest.audio_edits && (
                <Text>
                  <Text strong>Audio Edits: </Text> {soundTest.audio_edits}
                </Text>
              )}
            </Space>
            <AudioList list={soundTest.audio_list} />
          </div>
        )}
      </Load>
    </Page>
  );
};

const toggleThumbsUp = async (hash: string) => {
  const apiUrl = getUrlWithApiPrefix(`/toggle-like-test/${hash}`);

  const idToken = await firebase
    .auth()
    .currentUser?.getIdToken(/* forceRefresh */ false);

  if (!idToken) {
    throw new Error("Auth error. User not logged in");
  }

  const response = await fetch(apiUrl, {
    method: "POST",
    body: JSON.stringify({
      user_token: idToken,
    }),
    headers: {
      user_token: idToken,
    },
  });

  await response.json();
};

const submitFlag = async () => {};

const InteractionButtons = () => {
  const { testId }: { testId: string } = useParams();
  const [localThumbsUp, setLocalThumbsUp] = useState<number | null>(null);
  const [isLiked, setIsLiked] = useState<boolean | null>(null);

  const likedStyles = {
    color: "#165996",
    borderColor: "#165996",
  };

  return (
    <div style={{ height: 41.36 }}>
      <Load recoilState={soundTestAtom(testId)}>
        {(soundTest) => (
          <Load recoilState={isLoggedInAtom}>
            {(isLoggedIn) => (
              <>
                {isLoggedIn ? (
                  <Load recoilState={myLikedTestsAtom}>
                    {(likedTests) => {
                      const isThisTestLiked =
                        (isLiked == null &&
                          likedTests.find(
                            (test) =>
                              test.sound_test_id === soundTest.id &&
                              test.is_thumbs_up
                          )) ||
                        isLiked;

                      return (
                        <Button
                          style={{
                            marginRight: 7,
                            ...(isThisTestLiked ? likedStyles : {}),
                          }}
                          icon={<LikeOutlined />}
                          disabled={false}
                          onClick={(e) => {
                            e.currentTarget.blur();
                            setIsLiked(!isThisTestLiked);

                            if (!isThisTestLiked) {
                              setLocalThumbsUp(
                                localThumbsUp != null
                                  ? localThumbsUp + 1
                                  : soundTest.net_thumbs_up + 1
                              );
                            } else {
                              setLocalThumbsUp(
                                localThumbsUp != null
                                  ? localThumbsUp - 1
                                  : soundTest.net_thumbs_up - 1
                              );
                            }

                            toggleThumbsUp(soundTest.hash);
                          }}
                        >
                          {localThumbsUp == null &&
                          soundTest.net_thumbs_up > 0 ? (
                            <Text style={{ paddingLeft: 6 }}>
                              {soundTest.net_thumbs_up}
                            </Text>
                          ) : localThumbsUp != null && localThumbsUp > 0 ? (
                            <Text style={{ paddingLeft: 6 }}>
                              {localThumbsUp}
                            </Text>
                          ) : null}
                        </Button>
                      );
                    }}
                  </Load>
                ) : (
                  <Button
                    style={{ marginRight: 7, ...(isLiked ? likedStyles : {}) }}
                    icon={<LikeOutlined />}
                    disabled={true}
                  >
                    {soundTest.net_thumbs_up > 0 && (
                      <Text style={{ paddingLeft: 6 }}>
                        {soundTest.net_thumbs_up}
                      </Text>
                    )}
                  </Button>
                )}
                {/* <Button
                  icon={<FlagOutlined />}
                  disabled={isLoggedIn ? false : true}
                /> */}
              </>
            )}
          </Load>
        )}
      </Load>
    </div>
  );
};

export default ViewTest;
