import SoundTest from "../../types/SoundTest";
import { selector } from "recoil";
import getUrlWithApiPrefix from "../utils/getUrlWithApiPrefix";
import bustListsAtom from "./bustListsAtom";

const apiUrl = getUrlWithApiPrefix("/featured-sound-tests");

const featuredSoundTestsAtom = selector<SoundTest[]>({
  key: "featuredSoundTests",
  get: async ({ get }) => {
    get(bustListsAtom);

    const response = await fetch(apiUrl);
    const featuredTests = await response.json();

    return featuredTests;
  },
});

export default featuredSoundTestsAtom;
