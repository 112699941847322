import React from "react";
import { isLoggedInAtom } from "../state/auth";
import Load from "./Load";
import { Link } from "react-router-dom";

type Props = {
  children: NonNullable<React.ReactElement>;
};

const SignInLock = () => (
  <div>
    Please <Link to="/login">sign in</Link> to view this content
  </div>
);

const LoggedInLock = ({ children }: Props) => (
  <Load recoilState={isLoggedInAtom}>
    {(isLoggedIn) => (isLoggedIn ? children : <SignInLock />)}
  </Load>
);

export default LoggedInLock;
