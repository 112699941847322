import React from "react";
import Page from "./Page";
import LoggedInLock from "./LoggedInLock";
import { List, Typography } from "antd";
import Load from "./Load";
import mySoundTestsAtom from "../state/mySoundTests";
import { Link } from "react-router-dom";

const { Title } = Typography;

const Empty = () => (
  <Page title="My Account">
    <Title level={4} style={{ marginBottom: 20 }}>
      Sound Tests
    </Title>
    <LoggedInLock>
      <Load recoilState={mySoundTestsAtom}>
        {(soundTests) => (
          <List
            bordered
            dataSource={[...soundTests].reverse()}
            renderItem={(soundTest) => (
              <List.Item>
                <Link to={`/test/${soundTest.hash}`}>{soundTest.name}</Link>
              </List.Item>
            )}
          />
        )}
      </Load>
    </LoggedInLock>
  </Page>
);

export default Empty;
