import React, { FC } from "react";
import { List, Typography } from "antd";
import SoundTest from "../../types/SoundTest";
import { Link } from "react-router-dom";
import soundTestAtom from "../state/soundTest";

const { Link: AntLink } = Typography;

type Props = {
  soundTests: SoundTest[];
};

const SoundTestList: FC<Props> = ({ soundTests }) => (
  <List
    itemLayout="horizontal"
    dataSource={soundTests}
    renderItem={(soundTest) => (
      <List.Item>
        <div key={soundTest.id}>
          <Link to={`/test/${soundTest.hash}`}>{soundTest.name}</Link>
        </div>
      </List.Item>
    )}
  />
);

export default SoundTestList;
