import React, { FC } from "react";
import { Layout, Menu, Button, Typography } from "antd";
import { PlusCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Load from "./Load";
import { isLoggedInAtom } from "../state/auth";
import ErrorBoundary from "./ErrorBoundary";

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

type Props = {
  title: string;
};

const Page: FC<Props> = ({ children, title }) => (
  <ErrorBoundary>
    <Layout className="layout main-page-layout">
      <Header style={{ display: "flex" }}>
        <Link to="/">
          <div className="logo">kbcompare</div>
        </Link>
        <Menu theme="dark" mode="horizontal" className="top-menu">
          <Link to="/test/new">
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              shape="round"
              size="large"
            >
              New Test
            </Button>
          </Link>
          <Menu.Item className="top-menu-login">
            <Load recoilState={isLoggedInAtom}>
              {(isLoggedIn) => (
                <Link to={isLoggedIn ? "/my-account" : "/login"}>
                  <Menu.Item icon={isLoggedInAtom ? null : <UserOutlined />}>
                    {isLoggedIn ? "My Account" : "Log In"}
                  </Menu.Item>
                </Link>
              )}
            </Load>
          </Menu.Item>
        </Menu>
      </Header>
      <Content className="main-content-container">
        <Title>{title}</Title>
        {children}
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Built with{" "}
        <span role="img" aria-label="love">
          ❤️
        </span>{" "}
        by{" "}
        <a
          style={{ fontWeight: "bold", color: "rgba(255, 255, 255, 0.85)" }}
          href="http://keybonbon.com"
          target="_blank"
        >
          keybonbon
        </a>
      </Footer>
    </Layout>
  </ErrorBoundary>
);

export default Page;
