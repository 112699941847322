import React, { FC, useEffect } from "react";
import { useRecoilCallback } from "recoil";
import { isLoggedInAtom } from "../state/auth";
import { message } from "antd";
import firebase from "firebase/app";

const AuthProvider: FC = ({ children }) => {
  const initAuthAtom = useRecoilCallback(
    ({ set }) => async () => {
      const currUser = firebase.auth().currentUser;

      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          set(isLoggedInAtom, true);
        } else {
          set(isLoggedInAtom, false);
        }
      });

      if (currUser) {
        set(isLoggedInAtom, true);
        return;
      }

      const redirectRes = await firebase.auth().getRedirectResult();

      if (redirectRes.credential) {
        message.success("Logged In!");
        set(isLoggedInAtom, true);
      }
    },
    []
  );

  useEffect(() => {
    initAuthAtom();
  }, []);

  return <>{children}</>;
};

export default AuthProvider;
