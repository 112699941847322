import React from "react";
import GoogleButton from "react-google-button";
import Page from "./Page";
import { Card } from "antd";
import firebase from "firebase/app";
import Load from "./Load";
import { isLoggedInAtom } from "../state/auth";

const signIn = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithRedirect(provider);
};

const LoginSignUp = () => {
  return (
    <Page title="Login / Sign Up">
      <div className="login-page-container">
        <div className="login-card-container">
          <div style={{ marginBottom: 20 }}>
            Log In Status:
            <Load recoilState={isLoggedInAtom}>
              {(isLoggedIn) => (
                <>{isLoggedIn ? " Logged In" : " Not Logged In"}</>
              )}
            </Load>
          </div>
          <Card>
            Login or create an account by signing in with Google below
            <br />
            <div className="google-sign-in-container">
              <GoogleButton type="dark" onClick={signIn} />
            </div>
          </Card>
        </div>
      </div>
    </Page>
  );
};

export default LoginSignUp;
