import React, { FC } from "react";
import Load from "./Load";
import Page from "./Page";
import { Typography, Card, Row, Col, Divider, List } from "antd";
import featuredSoundTestsAtom from "../state/featuredSoundTests";
import topRankedSoundTestsAtom from "../state/topRankedSoundTests";
import LoggedInLock from "./LoggedInLock";
import SoundTestList from "./SoundTestList";
import mySoundTestsAtom from "../state/mySoundTests";

const { Title, Text } = Typography;

const Home: FC = () => (
  <Page title="Home">
    <Row gutter={16} className="home-cards">
      <Col className="gutter-row" span={8}>
        <Card size="default" className="card-home" title="Featured Sound Tests">
          <Load recoilState={featuredSoundTestsAtom}>
            {(soundTests = []) => <SoundTestList soundTests={soundTests} />}
          </Load>
        </Card>
      </Col>
      <Col className="gutter-row" span={8}>
        <Card
          size="default"
          className="card-home"
          title="Top Ranked Sound Tests"
        >
          <Load recoilState={topRankedSoundTestsAtom}>
            {(soundTests = []) => <SoundTestList soundTests={soundTests} />}
          </Load>
        </Card>
      </Col>
      <Col className="gutter-row" span={8}>
        <Card size="default" className="card-home" title="My Sound Tests">
          <LoggedInLock>
            <Load recoilState={mySoundTestsAtom}>
              {(soundTests = []) => (
                <SoundTestList
                  soundTests={[...soundTests].reverse().slice(0, 10)}
                />
              )}
            </Load>
          </LoggedInLock>
        </Card>
      </Col>
    </Row>

    <Divider />

    <Title level={2} className="home-lvl2-header">
      Tutorial
    </Title>
    <div className="home-tutorial-text">
      <Text>
        kbcompare is a free tool that lets you create sharable sound tests.
        Anyone can view and listen to sound tests, but you need an account in
        order to create and edit sound tests.
      </Text>
    </div>
    <div className="home-tutorial-text">
      <Text>
        Once you create an account, click on the "New Test" button in the header
        to create your test. Most fields are optional, but your test must have a
        name and at least one audio file (size limit 10MB per audio file, and at
        most 20 audio files per test).
      </Text>
    </div>

    <div className="home-tutorial-text" style={{ marginBottom: 30 }}>
      <Text>
        Below are some tips and suggestions to help you make a high quality
        audio test:
      </Text>
    </div>

    <div>
      <List
        bordered
        dataSource={[
          "Be descriptive in the test description, and include information about your audio setup. For example, include the microphone, audio interface, and the distance and angle the microphone was from the keyboard. Also include any edits you made to the audio, such as noise reduction.",
          "Edit the length of the audio files before uploading to kbcompare so that the audio files that you are comparing are of equal length. In future versions we will allow you to edit the length of the audio directly in the tool.",
          "Record the audio files in one sitting using the exact same audio setup, script, and typing speed. This will help eliminate external variables that may affect the outcome of the test.",
        ]}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </div>
  </Page>
);

export default Home;
