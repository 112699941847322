import React from "react";
import LoggedInLock from "./LoggedInLock";
import Page from "./Page";

const Empty = () => (
  <Page title="Edit Test">
    <LoggedInLock>
      <h1>hi</h1>
    </LoggedInLock>
  </Page>
);

export default Empty;
