import React, { FC } from "react";
import "./App.less";
import Home from "./components/Home";
import ViewTest from "./components/ViewTest";
import EditTest from "./components/EditTest";
import NewTest from "./components/NewTest";
import Login from "./components/Login";
import Account from "./components/Account";
import { Route, Switch, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { RecoilRoot } from "recoil";
import AuthProvider from "./components/AuthProvider";
import { Helmet } from "react-helmet";

const customHistory = createBrowserHistory();

const App: FC = () => (
  <RecoilRoot>
    <Helmet>
      <meta charSet="utf-8" />
      <title>kbcompare</title>
      <meta
        name="description"
        content="A website for creating and sharing keyboard audio tests and comparisons."
      />
    </Helmet>
    <AuthProvider>
      <Router history={customHistory}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/test/new">
            <NewTest />
          </Route>
          <Route path="/test/:testId/edit">
            <EditTest />
          </Route>
          <Route path="/test/:testId">
            <ViewTest />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/my-account">
            <Account />
          </Route>
        </Switch>
      </Router>
    </AuthProvider>
  </RecoilRoot>
);

export default App;
