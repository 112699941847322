import React, { FC, useState } from "react";
import AudioFile from "../../types/AudioFile";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import { Typography, Button } from "antd";

const { Text, Title } = Typography;

type Props = {
  list: AudioFile[];
};

const AudioList: FC<Props> = ({ list }) => {
  const [scrambledList, setScrambledList] = useState(list);
  const [isDataHidden, setIsDataHidden] = useState(false);

  return (
    <div className="test-audio-list">
      <Title level={4} style={{ marginBottom: 20 }}>
        Audio Files{" "}
        <Button
          onClick={(e) => {
            const target = e.currentTarget;

            target.blur();

            if (!isDataHidden) {
              setScrambledList(shuffle(scrambledList));
            }

            setIsDataHidden(!isDataHidden);
          }}
          className="audio-test-buttn-start"
        >
          {isDataHidden ? "Reveal Titles" : "Scramble and Hide Titles"}
        </Button>
      </Title>
      {scrambledList.map((audio) => {
        return (
          <AudioPlayer
            key={audio.url}
            src={audio.url}
            layout="horizontal"
            showJumpControls={false}
            customAdditionalControls={[]}
            customProgressBarSection={[
              RHAP_UI.CURRENT_TIME,
              RHAP_UI.PROGRESS_BAR,
            ].concat(isDataHidden ? [] : [RHAP_UI.DURATION])}
            header={
              <Text
                strong
                style={{
                  color: "black",
                  visibility: isDataHidden ? "hidden" : "visible",
                }}
              >
                {audio.name}
              </Text>
            }
          />
        );
      })}
    </div>
  );
};

const shuffle = <T extends {}>(originalArray: T[]): T[] => {
  const array = [...originalArray];
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export default AudioList;
