import SoundTest from "../../types/SoundTest";
import { selector } from "recoil";
import getUrlWithApiPrefix from "../utils/getUrlWithApiPrefix";
import firebase from "firebase/app";
import { isLoggedInAtom } from "./auth";
import bustListsAtom from "./bustListsAtom";

const apiUrl = getUrlWithApiPrefix("/my-sound-tests");

const mySoundTests = selector<SoundTest[]>({
  key: "myLikedSoundTestsAtom",
  get: async ({ get }) => {
    get(bustListsAtom);
    get(isLoggedInAtom);

    const idToken = await firebase
      .auth()
      .currentUser?.getIdToken(/* forceRefresh */ false);

    if (!idToken) {
      throw new Error("Auth error. User not logged in");
    }

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        user_token: idToken,
      },
    });

    const soundTests: SoundTest[] = await response.json();

    return soundTests;
  },
});

export default mySoundTests;
