import React, { useState } from "react";
import Page from "./Page";
import LoggedInLock from "./LoggedInLock";
import { Form, Input, Button, Upload, Space, message } from "antd";
import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import getUrlWithApiPrefix from "../utils/getUrlWithApiPrefix";
import firebase from "firebase/app";
import { Redirect } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import bustListsAtom from "../state/bustListsAtom";

const getUniqueId = ((val) => () => val++)(0);

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 20 },
};

type FileCollection = {
  [key: string]: any;
};

const AudioList = () => {
  const [fileList, setFileList] = useState<FileCollection>({});

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form.List name="audioList">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, idx) => {
              const uploadProps = {
                name: "file" + idx,
                accept: "audio/*",
                multiple: false,
                customRequest: () => {},
                beforeUpload: (file: any) => {
                  setFileList({
                    ...fileList,
                    [field.key]: file,
                  });

                  return true;
                },
                fileList: fileList[field.key] ? [fileList[field.key]] : [],
                showUploadList: {
                  showPreviewIcon: true,
                  showRemoveIcon: false,
                },
              };

              return (
                <div key={field.key}>
                  <Space>
                    <div style={{ width: "400px" }}>
                      <Form.Item
                        name={[field.name, "audioName"]}
                        fieldKey={[field.fieldKey, "audioName"]}
                        rules={[{ required: true, message: "Missing name" }]}
                        label="Audio Name"
                        className="audio-container-upload"
                      >
                        <Input placeholder="Cherry MX Black" />
                      </Form.Item>

                      <Form.Item
                        name={[field.name, "audioFile"]}
                        fieldKey={[field.fieldKey, "audioFile"]}
                        label="Audio File (limit 10MB)"
                        getValueFromEvent={normFile}
                        rules={[{ required: true, message: "Missing file" }]}
                        className="audio-container-upload"
                      >
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />}>
                            Click to upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </div>

                    <MinusCircleOutlined
                      className="minus-circle-audio"
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </Space>
                </div>
              );
            })}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add({
                    key: getUniqueId(),
                  });
                }}
                block
              >
                <PlusOutlined /> Add Audio File
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

type TestFormData = {
  name: string;
  description?: string;
  recordingSetup?: string;
  audioEdits?: string;
  audioList: Array<{
    key: number;
    audioName: string;
    audioFile: Array<{
      name: string;
      originFileObj: File;
      size: number;
      uid: string;
      type: string;
    }>;
  }>;
};

const newTestApiUrl = getUrlWithApiPrefix("/new-sound-test");

type InitialStatus = {
  status: "initial";
};

type LoadingStatus = {
  status: "loading";
};

type ErrorStatus = {
  status: "error";
  errorMsg: string;
};

type SuccessStatus = {
  status: "success";
  payload: {
    audioUrls: string[];
    soundTestHash: string;
  };
};

type Status = InitialStatus | LoadingStatus | ErrorStatus | SuccessStatus;

const NewTest = () => {
  const [form] = Form.useForm();
  const [status, setStatus] = useState<Status>({
    status: "initial",
  });
  const setBustListAtom = useSetRecoilState(bustListsAtom);

  const onFinish = async (val: TestFormData) => {
    try {
      setStatus({
        status: "loading",
      });

      const formData = new FormData();

      formData.append("name", val.name);
      formData.append("description", val.description ?? "");
      formData.append("recording_setup", val.recordingSetup ?? "");
      formData.append("audio_edits", val.audioEdits ?? "");

      if (!val.audioList?.length) {
        throw new Error("Must provide at least one audio file");
      }

      val.audioList.forEach((audio) => {
        formData.append(`files`, audio.audioFile[0].originFileObj);
      });

      formData.append(
        "fileInfo",
        JSON.stringify(
          val.audioList.map((audio) => ({ name: audio.audioName }))
        )
      );

      const idToken = await firebase
        .auth()
        .currentUser?.getIdToken(/* forceRefresh */ false);

      if (!idToken) {
        throw new Error("Auth error. User not logged in");
      }

      formData.append("user_token", idToken);

      const responseObj = await fetch(newTestApiUrl, {
        method: "POST",
        body: formData,
      });

      const response = await responseObj.json();

      if (responseObj.status !== 200) {
        message.error(`Error: ${responseObj.statusText}`);

        return setStatus({
          status: "error",
          errorMsg: responseObj.statusText,
        });
      }

      setStatus({
        status: "success",
        payload: response,
      });

      setBustListAtom((currVal) => currVal + 1);
    } catch (err) {
      message.error(`${err}`);

      setStatus({
        status: "error",
        errorMsg: err,
      });
    }
  };

  return (
    <Page title="New Test">
      {status.status === "success" && (
        <Redirect to={`/test/${status.payload.soundTestHash}`} />
      )}
      <LoggedInLock>
        <Form {...layout} layout="horizontal" form={form} onFinish={onFinish}>
          <Form.Item
            label="Test Name"
            name="name"
            rules={[{ required: true, message: "Missing name" }]}
          >
            <Input placeholder="Linear Switch Comparison" />
          </Form.Item>
          <Form.Item label="Test Description" name="description">
            <Input.TextArea placeholder="Testing 10 different MX style linear switches" />
          </Form.Item>
          <Form.Item label="Recording Setup" name="recordingSetup">
            <Input placeholder="Sennheiser 10, Apollo Twin Duo, 7 inches from board" />
          </Form.Item>

          <Form.Item label="Audio Edits" name="audioEdits">
            <Input placeholder="Noise removal in Audacity" />
          </Form.Item>

          <div className="audio-list-container">
            <AudioList />
          </div>

          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              loading={status.status === "loading"}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </LoggedInLock>
    </Page>
  );
};

export default NewTest;
